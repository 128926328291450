import $http from '@/utils/http';

/**
 * 空间管理 - 添加/修改
 * @param data
 * @returns {Promise<unknown>}
 */
export function cloudspaceSetsave(data) {
  return $http.post('/api/cloudspace/setsave', data, 'loadingDiv');
}

/**
 * 空间管理 - 列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function cloudspaceGetlist(data) {
  return $http.get('/api/cloudspace/getlist', data, 'loadingDiv');
}

/**
 * 空间管理 - 详情
 * @param data
 * @returns {Promise<unknown>}
 */
export function cloudspaceGetinfo(data) {
  return $http.post('/api/cloudspace/getinfo', data, 'loadingDiv');
}

/**
 * 空间管理 - 删除
 * @param data
 * @returns {Promise<unknown>}
 */
export function cloudspaceSetdel(data) {
  return $http.post('/api/cloudspace/setdel', data, 'loadingDiv');
}

/**
 * 数字构建列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function digital_componentIndex(data) {
  return $http.get('/api/digital_component/index', data, 'loadingDiv');
}

/**
 * 相册列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function album_list(data) {
  return $http.get('/api/album_list', data, 'loadingDiv');
}

/**
 * 获取上传的空间列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function getuploadspace(data) {
  // return $http.post('/api/cloudspace/getuploadspace', data, 'loadingDiv');
  return $http.post('/v2/cloud_space/index', data, 'loadingDiv');
}

/**
 * 案例列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function casemanageCase_list(data) {
  return $http.get('/api/casemanage/case_list', data, 'loadingDiv');
}

/**
 * 空间管理 - 获取空间使用详情
 * @param data
 * @returns {Promise<unknown>}
 */
export function getusedsizeinfo(data) {
  return $http.post('/api/cloudspace/getusedsizeinfo', data, 'loadingDiv');
}
