<template>
  <div class="">
    <div>
      <div class="top">
        <h1 style="margin-left: 30px">案例库</h1>
        <div style="padding: 15px 23px">
          <!-- <el-button type="" size="small">多选</el-button> -->
          <el-button type="" size="small" @click="UploadTheCase">上传案例</el-button>
        </div>
      </div>
      <div style="padding: 0 20px">
        <el-card style="border-radius: 10px">
          <div class="head" style="margin-bottom: 20px">
            <div style="display: flex">
              <p
                v-for="(item, index) in getuploadspaceData"
                :key="item.id"
                :class="{ Selected: Selected === index }"
                @click="spaceFiltrate(index)"
              >
                {{ item.name }}
              </p>
              <!-- <p :class="{ Selected: Selected === 2 }" @click="Handover(2)">
                公库
              </p>
              <p :class="{ Selected: Selected === 3 }" @click="Handover(3)">
                XX盘(公共空间)
              </p>
              <p :class="{ Selected: Selected === 4 }" @click="Handover(4)">
                XX盘
              </p> -->
            </div>
            <div>
              <el-select
                v-model="value"
                placeholder="按状态筛选"
                style="margin-right: 10px"
                @change="ScreeningOfCase"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-input
                v-model="input"
                placeholder="按关键词搜索案例"
                style="width: 215px; margin-right: 20px"
                @input="CaseSearch"
              ></el-input>
            </div>
          </div>
          <div v-if="list.length != 0">
            <div style="display: flex; flex-wrap: wrap">
              <div
                class="card"
                :body-style="{ padding: '0px' }"
                v-for="item in list"
                :key="item.id"
                @click="redact(item.id)"
              >
                <img :src="item.main_image" class="image" style="object-fit: cover" />
                <div style="padding: 14px">
                  <span style="font-size: 15px">{{ item.title }}</span>
                  <div class="bottom clearfix">
                    <img :src="item.avatar" class="one" style="object-fit: cover" />
                    <el-tag class="state" v-if="item.status === 1">已启用</el-tag>
                    <el-tag type="danger" v-else class="state">已禁用</el-tag>
                    <div class="redact" @click="relevancyCase(item.id, item.title, $event)">
                      关联构建
                    </div>
                    <span>{{ item.nickname }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="text-align: center; margin: 25px 0">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.page"
                :page-sizes="[10, 20, 30, 35]"
                :page-size="page.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page.total"
              ></el-pagination>
            </div>
          </div>
          <div v-else class="NoData">暂无数据</div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
// import CaseLndex from "./components/caseLndex.vue";
// import EditCase from "./components/EditCase.vue";
import { case_lsit } from '@/common/case.js';
import { getuploadspace } from '@/common/memory.js';

export default {
  // 案例库
  name: 'case',
  // 组件参数 接收来自父组件的数据
  props: {},
  // import引入的组件需要注入到对象中才能使用
  // components: { CaseLndex, EditCase },
  data() {
    // 这里存放数据
    return {
      isDetails: 1,
      value: [],
      input: '',
      Selected: 0,
      options: [],
      page: {
        page: 1,
        limit: 10,
        total: 0,
      },
      list: [],
      getuploadspaceData: [],
    };
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
    getuploadspace().then((res) => {
      let { code, result } = res;
      if (code == 200) {
        this.getuploadspaceData = result;
        this.getuploadspaceData.unshift({ id: 0, name: '所有空间', file_path: '' });
      }
    });
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 案例列表
    getList(status, search, space_id) {
      let dt = {
        page: this.page.page,
        limit: this.page.limit,
        status,
        search,
        space_id,
      };
      case_lsit(dt).then((res) => {
        let { code, result } = res;
        if (code === 200) {
          this.list = result.data;
          this.page.total = result.total;
          this.options = result.c_status;
        }
      });
    },
    // 案例关联
    relevancyCase(id, title, e) {
      e.stopPropagation();
      // this.isDetails = 2;
      this.$router.push({
        path: 'LinkBuilding',
        query: {
          id: id,
          title: title,
        },
      });
    },
    // 案例编辑
    redact(id) {
      this.$router.push({
        path: 'caseAdd',
        query: {
          id: id,
        },
      });
    },
    // 上传案例
    UploadTheCase() {
      // console.log(this.getuploadspaceData);
      // console.log(this.Selected);
      // console.log(this.getuploadspaceData[this.Selected].id);
      let spaceId = this.getuploadspaceData[this.Selected].id;
      this.$router.push({
        path: 'caseAdd',
        query: {
          spaceId,
        },
      });
    },
    // 空间筛选
    spaceFiltrate(index) {
      this.Selected = index;
      this.getList('', '', index);
    },
    // 状态筛选案例
    ScreeningOfCase(val) {
      this.getList(val);
    },
    // 搜索案例
    CaseSearch(val) {
      this.getList('', val);
    },
    // 分页
    handleSizeChange(size) {
      this.page.limit = size;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.getList();
    },
    // 剪切图片
    closeDialod() {
      this.caseId = 0;
      this.dialogFormVisible = false;
      // this.init();
    },
  },

  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  // 这里存放数据
  beforeCreate() {},
  // 生命周期 - 挂载之前
  beforeMount() {},
  // 生命周期 - 更新之前
  beforeUpdate() {},
  // 生命周期 - 更新之后
  updated() {},
  // 生命周期 - 销毁之前
  beforeDestroy() {},
  // 生命周期 - 销毁完成
  destroyed() {},
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
};
</script>
<style lang="scss" scoped>
// @import url(); 引入公共css类
.top {
  display: flex;
  justify-content: space-between;

  .el-button {
    width: 100px;
  }

  .el-button:hover {
    background-color: #000;
    color: #fff;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-self: center;

  .Selected {
    border-radius: 5px;
    background-color: #575757;
    margin-right: 20px;
    font-size: 14px;
    color: #fff;
  }

  p {
    padding: 7px;
    margin-right: 10px;
    font-size: 14px;
    color: #333;
    line-height: 12px;
    cursor: pointer;
  }
}

.card:hover .redact,
.redact:hover {
  display: block;
}

.card {
  cursor: pointer;
  position: relative;
  width: 210px;
  margin-right: 14px;
  margin-bottom: 10px;
  background-color: #f2f2f2;

  img {
    width: 210px;
    height: 210px;
  }

  .state {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .redact {
    position: absolute;
    top: 170px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    text-align: center;
    width: 100%;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    color: white;
    display: none;
  }

  .clearfix {
    margin-top: 5px;
    display: flex;

    .one {
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }

    span {
      font-size: 13px;
      line-height: 30px;
      margin-left: 10px;
    }
  }
}

.NoData {
  line-height: 50px;
  text-align: center;
  color: #bbb;
}
</style>
