import $http from '@/utils/http';
// 获取案例列表
export function case_lsit(data) {
  return $http.get('/api/casemanage/case_list', data);
}
// 案例详情(旧)
export function case_info(id) {
  return $http.post('/api/casemanage/case_info', id);
}
// 新增案例上传图片
export function case_upload(data) {
  return $http.post('/api/casemanage/case_upload', data);
}
// 案例详情(新)
export function new_getinfo(id) {
  return $http.post('/api/casemanage/new_getinfo', id);
}
